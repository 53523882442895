import React from "react";
import Collaborators from "./collaborators";
import './collaborators.css'


const Collabo = () =>{
    return(
        <div className="pty">
            <h3>PARTNERSHIPS</h3>
            <div className="partnerships">
                <div>
                    <Collaborators
                    collabImg="/imgs/logos/444.jpg"
                    collabwriteup="Biz Baz Events is excited to partner with Radio 44, Kenya's premier Bollywood-Hindi Pop station. Blending Bollywood, Hindi Pop, English, Kenyan, and African hits, Radio 44 offers a unique listening experience. Tune in to 100.8 FM or visit radio44.co.ke"
                    collabDates="December 2024"
                    />
                </div>
                
                <div>
                    <Collaborators
                    collabImg="/imgs/logos/AirAsia-X-Logo.jpg"
                    collabwriteup="Biz Baz Events is thrilled to partner with AirAsia X, marking a historic milestone with their inaugural direct flight from Kuala Lumpur to Nairobi. As the first ASEAN low-cost carrier to connect directly to Kenya, this expansion highlights AirAsia X's commitment to global connectivity. Biz Baz Events is proud to collaborate on this exciting journey."
                    collabDates="December 2024"
                    />
                </div>
                <div>
                    <Collaborators
                    collabImg="/images/If We Build It (large_logo_transparent).webp"
                    collabwriteup="Biz Baz Events is not only an event partner with the Nairobi Baseball Community under the brand If We Build It - we will be hosting some of our vendors to give you a taste of the Bizarre Bazaar Festival Experience.All products are Proudly Made in Kenya "
                    collabDates="September 15th 2024"
                    />
                </div>
            </div>
        </div>
    )
}


export default Collabo